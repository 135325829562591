.App {
  text-align: center;
  box-shadow: 3px 3px 4px 5px lightblue;
  background-color: white;
  margin: 2%;
  width: 33.33%;
  margin-left: 30%;
  border-radius: 25px;
  font-family: cursive;
  padding: 5%;
  overflow: hidden;
  color: gray;
}

.validate {
  display: inline-block;
  border-radius: 4px;
  background-color: rgb(65, 181, 226);
  border: none;
  color: white;
  text-align: center;
  font-size: 28px;
  padding: 3px;
  width: 200px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 3px;
  font-family: cursive;
  box-shadow: 3px 3px 2px 3px rgb(143, 192, 192);
}

.validate span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.validate span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.validate:hover span {
  padding-right: 25px;
}

.validate:hover span:after {
  opacity: 1;
  right: 0;
}

.btn {
  display: inline-block;
  border-radius: 4px;
  background-color: rgb(65, 181, 226);
  border: none;
  color: white;
  text-align: center;
  font-size: 28px;
  padding: 2px;
  width: 300px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
  font-family: cursive;
  box-shadow: 3px 3px 2px 3px rgb(143, 192, 192);
}

.btn span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.btn span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.btn:hover span {
  padding-right: 25px;
}

.btn:hover span:after {
  opacity: 1;
  right: 0;
}

.image1 {
  border-radius: 50%;
  padding: 5%;
  margin: 3%;
}
